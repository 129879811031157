module.exports = {
    language: {
        name: '中文'
    },
    nav: {
        homePage: 'homePage',
        travelStrategy: 'travel',
        recommendTheScenicSpot: 'recommend',
        specialtyFood: 'specialty',
        popularInformation: 'popular',
        contactUs: 'contactUs'
    },
    bottom: {
        yqlj:'blogroll',
        lyjgw:'Tourism Board',
        jqwz:'Scenic sites',
        hzwz:'Cooperation',
        zwwz:'Government affairs',
        gjlyj:'National Tourism Administration',
        zjklyzxw:'Zhangjiakou Tourism information network',
        zjklyj:'Zhangjiakou Tourism Bureau',
        zjklyw:'Zhangjiakou tourism network',
        zjkysp:'Mountain slopes of Zhangjiakou',
        zjkly:'Zhangjiakou Tourism',
        xc:'Ctrip',
        fz:'Fliggy',
        lmm:'The ass mother',
        whlyb:'Ministry of Culture and Tourism',
        zjk:'Zhangjiakou',
        zjkzx:'Zhangjiakou Online',
        bottomContentOne: 'Zhangjiakou Cultural Broadcasting and Tourism Bureau',
        bottomContentTwo: 'Beijing ICP B no.15036610-3',
        bottomContentThree: 'Zhongzhi You (Beijing) Technology Co., LTD',
        technicalSupport: 'technical support',
        citgroup: 'citgroup',
        zjkGovernment: "Zhangjiakou Municipal People's Government",
        zjkCBATB: 'Zhangjiakou Cultural Broadcasting and Tourism Bureau',
        ministryOfCultureAndTourism: 'Ministry of Culture and Tourism',
        chinaTourismAcademy: 'China Tourism Academy',
        beijingCultureAndTourismBureau: 'Beijing Culture and Tourism Bureau',
        hebeiProvincialDepartmentOfCultureAndTourism: 'Hebei Provincial Department of Culture and Tourism',
    },
    content: {
        zhangjiakouChina: 'Zhangjiakou, China',
        browseMore: 'more',
        oneSBelovedMotherland: "one's beloved motherland",
        foodProducts: 'Food products',
        foodProductsContent: 'From the beautiful and rich Sanggan river to the rich grassland on the dam, there are local people everywhere can not get tired of eating, outsiders rare famous snacks.',
        cultureAndArt: 'culture and art',
        cultureAndArtContent: 'Generous elegy and bold and bold blend, with a strong flavor of The Times and political color.',
        characteristicLine: 'Characteristic line',
        characteristicLineContent: 'Look at the Great Wall inside and outside, you can enjoy great rivers and mountains, and enjoy the great beauty of bashang grassland on the classic grassland pastoral tour.',
        picturesque: 'picturesque',
        travelGuide: 'Travel guide',
        inTheBeauty: 'In the beauty',
        qiaoxiqu: "qiaoxi",
        chongliqu: "Chung li area",
        huailaixian: "Huailai county",
        chichengxian: "Chicheng county",
        zhangbeixian: "Zhangbei county",
        theRecommendedStrategy: 'The recommended strategy',
        theRecommendedStrategyContent: 'After 365 days of practical experience for you to select the most beautiful route planning recommendations',
        hotLine: 'Hot line',
        inTheBeauty: 'In the beauty',
        knowMoreAbout: 'more',
        popularDestination: 'popular destination',
        viewDetails: 'view details',
        recommendedCircuit: 'Recommended circuit',
        viewMore: 'more',
        savorFood: 'savor food',
        aboutUs: 'About Us',
        aboutUsContent: "There are countless delicacies and snacks in China. However, there is such a beautiful city in Hebei province. Its name is Zhangjiakou.It not only has xuanhua ancient city, Nuanquan ancient town, Qiaoxi Dayingmen scenic spot, Guyuan saiwai manor and other interesting attractions, when it comes to food is also indispensable this ancient cultural capital.The city streets of Zhangjiakou are busy, and the special food is also very good. As a foodie, I can't wait to share with you the delicious food in Zhangjiakou.Speaking of the friends in the north are no strangers, oat noodle is a good health food, which is helpful for weight loss and beauty.Eat you flour it is important ingredients, authentic ingredients have kimchi soup, pork soup, chicken soup, etc., another is to use vinegar, sesame oil, salt, MSG, soup made of chopped green onion, coriander, use you flour clings to eat, very delicious.You noodle can also be Fried and cold: Fried: the pot into the cooking oil, open oil into the onion, ginger, garlic stir fry, add an appropriate amount of vegetables with spices, stir fry until medium-cooked, cut into the cooked food, until the delicious finish.Such tempting pasta is even more popular.",
        specialty: 'specialty',
        newsRankings: 'News rankings',
        releaseTime: 'release time',
        source: 'source',
        recommendedAttractions: 'Recommended attractions',
        recommendedDailyNews: 'Recommended Daily News',
        theGamesAreZone: 'The games are zone',
        cityCard: 'city card',
    }
}