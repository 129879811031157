import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/home'
  },
  {
    path:'/home',
    component: ()=> import('../components/Home/home.vue'),
    meta:{
      // 页面标题title
      title: '大好河山张家口 - 中智游'
    }
  },
  {
    path:'/travelStrategy',
    component: ()=> import('../components/travelStrategy'),
    meta:{
      title: '旅游攻略-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/location',
    component: ()=> import('../components/location'),
    meta:{
      title: '推荐景区-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/shop',
    component: ()=> import('../components/shop/index.vue'),
    meta:{
      title: '特产美食-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/hotNews',
    component: ()=> import('../components/hotnews/index.vue'),
    meta:{
      title: '热门资讯-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/connectUs',
     component: ()=> import('../components/ContactUs.vue'),
     meta:{
      title: '联系我们-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/more',
    component: ()=> import('../components/more/more.vue'),
    meta:{
      title: '热门资讯-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/publicdetails',
    component: ()=> import('../components/publicdetails/publicdetails.vue'),
    meta:{
      title: '大好河山张家口 - 中智游'
    }
  },
  {
    path:'/citynamedetail',
    component: ()=> import('../components/citynamedetail/citynamedetail.vue'),
    meta:{
      title: '热门资讯-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/teselinedetails',
    component: ()=> import('../components/teselinedetails/teselinedetails.vue'),
    meta:{
      title: '热门资讯-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/public',
    component: ()=> import('../components/public/public.vue'),
    meta:{
      title: '美食特产-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/publicculture',
    component: ()=> import('../components/publicculture/publicculture.vue'),
    meta:{
      title: '文化艺术-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/teseline',
    component: ()=> import('../components/teseline/teseline.vue'),
    meta:{
      title: '特色线路-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/fjrhdetails',
    component: ()=> import('../components/fjrhdetails/fjrhdetails.vue'),
    meta:{
      title: '热门资讯-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/yjgldetails',
    component: ()=> import('../components/yjgldetails/yjgldetails.vue'),
    meta:{
      title: '热门资讯-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/cityname',
    component: ()=> import('../components/cityname/cityname.vue'),
    meta:{
      title: '城市名片-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/dqmjmore',
    component: ()=> import('../components/dqmjmore/dqmjmore.vue'),
    meta:{
      title: '推荐景点-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/publicdqmjdetails',
    component: ()=> import('../components/publicdqmjdetails/publicdqmjdetails.vue'),
    meta:{
      title: '大好河山张家口 - 中智游'
    }
  },
  {
    path:'/publicdqmjdetailstwo',
    component: ()=> import('../components/publicdqmjdetailstwo/publicdqmjdetailstwo.vue'),
    meta:{
      title: '大好河山张家口 - 中智游'
    }
  },
  {
    path:'/wenhuayishupublicdetails',
    component: ()=> import('../components/wenhuayishupublicdetails/wenhuayishupublicdetails.vue'),
    meta:{
      title: '大好河山张家口 - 中智游'
    }
  },
  {
    path:'/tuijiangongluemore',
    component: ()=> import('../components/tuijiangongluemore/tuijiangongluemore.vue'),
    meta:{
      title: '推荐景点-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/remenlinedetails',
    component: ()=> import('../components/remenlinedetails/remenlinedetails.vue'),
    meta:{
      title: '大好河山张家口 - 中智游'
    }
  },
  {
    path:'/liaojiegengduodetails',
    component: ()=> import('../components/liaojiegengduodetails/liaojiegengduodetails.vue'),
    meta:{
      title: '大好河山张家口 - 中智游'
    }
  },
  {
    path:'/ceshidatails',
    component: ()=> import('../components/ceshidatails/ceshidatails.vue'),
    meta:{
      title: '大好河山张家口 - 中智游'
    }
  }, 
  {
    path:'/yjdetail',
    component: ()=> import('../components/yjdetail/yjdetail.vue'),
    meta:{
      title: '大好河山张家口 - 中智游'
    }
  },
  {
    path:'/chakangd',
    component: ()=> import('../components/chakangd/chakangd.vue'),
    meta:{
      title: '热门资讯-大好河山张家口 - 中智游'
    }
  },
  {
    path:'/xinwenpaihangmore',
    component: ()=> import('../components/xinwenpaihangmore/xinwenpaihangmore.vue'),
    meta:{
      title: '大好河山张家口 - 中智游'
    }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
