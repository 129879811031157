import Vue from 'vue'
import App from './App.vue'
import router from './router'
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
     loading: require('./assets/timg.gif'),
     error: require('./assets/404.png')
});
import VueAwesomeSwiper from 'vue-awesome-swiper';
import '../node_modules/swiper/swiper-bundle.css'
// import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper,)

Vue.prototype.$http  = axios
Vue.use(ElementUI);
Vue.config.productionTip = false

let urlBase = {
  api: 'http://cmsback.zyzjk.com',
  locale: 'zh'
};
if (localStorage.getItem("baseURL")) {
  urlBase.api = localStorage.getItem("baseURL");
  urlBase.locale = 'en';
}

axios.defaults.baseURL = urlBase.api;


//中英文切换
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n=new VueI18n({
    locale:urlBase.locale,
    messages:{
        'zh':require('./components/language/zh'),
        'en':require('./components/language/en')
    }
})

new Vue({
  router,
  store,
  i18n, 
  render: h => h(App)
}).$mount('#app')
