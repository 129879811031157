<template>
  <div id="app">
   <Nav> </Nav>
   <router-view></router-view>
  </div>
</template>
<script>
import Nav from './views/navSiper'
export default {
  name: '',
  components:{
    Nav, 
  }
}
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}
ul li{
  list-style: none;
}
.box{
  height: 5531px;
  width: 100%;
  background: #fff;
}
</style>
