<template>
   <div class="block">
    <div class="nav">
      <div class="banxin">
        <div class="h1"></div>
        <div class="navTitle">
          <div :class="$route.path === '/home'? 'isShow ' : '' " @click="home" >{{$t('nav.homePage')}}</div>
          <div :class="$route.path === '/travelStrategy'? 'isShow ' : '' " @click="travelStrategy"> {{$t('nav.travelStrategy')}}</div>
          <div :class="$route.path === '/location'? 'isShow ' : '' " @click="location">{{$t('nav.recommendTheScenicSpot')}}</div>
          <div :class="$route.path === '/shop'? 'isShow ' : '' " @click="shop">{{$t('nav.specialtyFood')}}</div>
          <div :class="$route.path === '/hotNews'? 'isShow ' : '' " @click="hotNews">{{$t('nav.popularInformation')}}</div>
          <div :class="$route.path === '/connectUs'? 'isShow ' : '' " @click="connectUs">{{$t('nav.contactUs')}}</div>
          <!-- <div @click="changeLanguage()">{{$t('language.name')}}</div> -->
          <div class="languageswitch">
            <span class="en" :class="{'active':!isActive}" @click="changeLanguage('en')">English</span>
            <span class="zh" :class="{'active':isActive}" @click="changeLanguage('zh')">中文</span>
          </div>   
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      isActive: true
    }
  },
  mounted(){
    if (localStorage.getItem("baseURL")) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  },
  methods:{
    home(){
      this.$router.push('/home').catch(()=>{})
    },
    travelStrategy(){
      this.$router.push('/travelStrategy').catch(()=>{})
    },
    location(){
      this.$router.push('/location').catch(()=>{})
    },
    shop(){
      this.$router.push('/shop').catch(()=>{})
    },
    hotNews(){
      this.$router.push('/hotNews').catch(()=>{})
    },
    connectUs(){
      this.$router.push('/connectUs').catch(()=>{})
    },
    changeLanguage(locale){
      if(locale == 'zh') {
        localStorage.removeItem("baseURL");
      } else {
        localStorage.setItem("baseURL", "http://ecmsback.zyzjk.com");
      }
      window.location.reload();
    },
  }
}
</script>

<style lang="less" scoped>
.isShow{
  height: 88px;
  background: rgba(0, 0, 0, 0.3);
  padding: 0 16px 0 15px;
}
.block {
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
}

/* 导航 */
.block .nav {
  width: 100%;
  height: 88px;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
  margin: 0 auto;
}
.block .nav .banxin {
  width: 1200px;
  margin: 0 auto;
  /* height: 100px; */
  background-color: red;
}
.block .nav .banxin .h1 {
  width: 322px;
  height: 73px;
  background: url(http://qiniu.image.citgroup.cn/hebei/zhangjiakou/h1logo.png);
  position: absolute;
  top: 6px;
  margin-right: 292px;
  float: left;
}
.block .nav .banxin .navTitle {
  /* background: pink; */
  height: 100%;
  width: 685px;
  /* position: absolute; */
  /* left: 815px; */
  display: flex;
  justify-content: space-around;
  line-height: 88px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #ffffff;
  float: right;
  cursor: pointer;
}
.block .nav .banxin .navTitle .shouye {
  width: 72px;
  height: 88px;
}
.languageswitch {
  width: 146px;
  height: 27px;  
  border: 1px solid #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  margin-top: 30px;
  line-height: 30px;
  display: flex;
  justify-content: center;
}
.languageswitch .en {
  width: 73px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  //color: #FFFFFF;
}
.languageswitch .zh {
  width: 73px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  //color: #FFFFFF;
}
.active {
  background-color: #fff;
  color: #39A1FE;
}
</style>
