module.exports = {
    language: {
        name: 'English'
    },
    nav: {
        homePage: '首页',
        travelStrategy: '旅游攻略',
        recommendTheScenicSpot: '推荐景区',
        specialtyFood: '特产美食',
        popularInformation: '热门资讯',
        contactUs: '联系我们'
    },
    bottom: {
        yqlj:'友情链接',
        lyjgw:'旅游局网站',
        jqwz:'景区网站',
        hzwz:'合作网站',
        zwwz:'政务网站',
        gjlyj:'国家旅游局',
        zjklyzxw:'张家口旅游资讯网',
        zjklyj:'张家口旅游局',
        zjklyw:'张家口旅游网',
        zjkysp:'张家口野山坡',
        zjkly:'张家口旅游',
        xc:'携程',
        fz:'飞猪',
        lmm:'驴妈妈',
        whlyb:'文化旅游部',
        zjk:'张家口',
        zjkzx:'张家口在线',
        bottomContentOne: '张家口市文化广电和旅游局',
        bottomContentTwo: '京ICP备15036610号-3',
        bottomContentThree: '中智游(北京)科技有限公司',
        technicalSupport: '技术支持',
        citgroup: '中智游',
        zjkGovernment: '张家口市人民政府',
        zjkCBATB: '张家口市文化广电和旅游局',
        ministryOfCultureAndTourism: '文化和旅游部',
        chinaTourismAcademy: '中国旅游研究院',
        beijingCultureAndTourismBureau: '北京市文化和旅游局',
        hebeiProvincialDepartmentOfCultureAndTourism: '河北省文化和旅游厅',
        
    },
    content: {
        zhangjiakouChina: '中国张家口',
        browseMore: '浏览更多',
        oneSBelovedMotherland: '大好河山',
        foodProducts: '美食特产',
        foodProductsContent: '从美丽富饶的桑干河畔到水草丰盛的坝上草原，到处都有当地人百吃不厌、外地人稀罕的名品小吃。',
        cultureAndArt: '文化艺术',
        cultureAndArtContent: '慷慨悲歌与粗犷豪放相交融， 具有浓厚时代气息和政治色彩。',
        characteristicLine: '特色线路',
        characteristicLineContent: '望长城内外，游大好河山，草原牧歌经典旅游线大美坝上草原，尽享自由自在。',
        picturesque: '风景如画',
        travelGuide: '游记攻略',
        inTheBeauty: '地区美景',
        qiaoxiqu: "桥西区",
        chongliqu: "崇礼区",
        huailaixian: "怀来县",
        chichengxian: "赤城县",
        zhangbeixian: "张北县",
        theRecommendedStrategy: '推荐攻略',
        theRecommendedStrategyContent: '推经过365天的实际体验为您精挑细选出最优美尽兴的路线规划荐攻略',
        hotLine: '热门线路',
        inTheBeauty: '当季美景',
        knowMoreAbout: '了解更多',
        popularDestination: '热门景点',
        viewDetails: '查看详情',
        recommendedCircuit: '推荐线路',
        viewMore: '查看更多',
        savorFood: '品尝美食',
        aboutUs: '关于我们',
        aboutUsContent: '中国的美食小吃数不胜数，然而偏偏在河北有这么一个美丽的城市，它的名字叫做张家口。它不仅有宣化古城、暖泉古镇、桥西大境门风景区、沽源塞外庄园等好玩的景点，说到吃也少不了这座文化古都。张家口的城市街道繁华，美食特色小吃也很棒，作为吃货我的在这里已经迫不及待要给大家分享一下张家口都有哪些美味了。说到攸面北方的朋友并不陌生，莜面是一种很好的保健食品，有助于减肥和美容。吃莜面很重要的一点就是作料，正宗的作料有泡菜汤、猪肉汤、鸡汤等等，还有一种就是用醋、香油、盐、味精、葱花、香菜调制成的汤，用莜面沾着吃，非常的好吃。莜面还可以炒着吃和凉拌：炒着吃：锅里放入食用油，油开放入葱，姜，蒜爆炒，加入适量配菜加入调味品，炒至七分熟，将莜面切开放入，待菜熟美味即完成。如此诱人的面食更是受到了更多人的喜欢。',
        specialty: '特产',
        newsRankings: '新闻排行',
        releaseTime: '发布时间',
        source: '来源',
        recommendedAttractions: '推荐景点',
        recommendedDailyNews: '每日推荐新闻',
        theGamesAreZone: '冬奥专区',
        cityCard: '城市名片',
    }
}